import React from "react";
import './Docs.css';
import { LuPlus } from "react-icons/lu";
import { RxCaretDown } from "react-icons/rx";
import { RiUpload2Line } from "react-icons/ri";
import { IoMdSync } from "react-icons/io";
import { BsFillLightningChargeFill } from "react-icons/bs";
import imOne from '../im1.png';
import imTwo from '../im2.png';
import imThree from '../im3.png';
import workSheet from '../worksheet-shot.png';
import companyProfile from '../worksheet-shottwo.png';
import payroll from  '../DOWNLOAD-PAYROLL.png';
import dataSheet from '../datasheeeet.jpg'


const Docs = ({ showModal, modalToggler })=>{
    
    return(<article style={{
        // marginTop:'3em'
    }}>

        <section className="articless_top_pekjfruiojk">
            <div className="same_degn new pointer">
                <LuPlus />
                &#160;
                &#160;
                New

                <RxCaretDown />
            </div>

            <div className="same_degn">
                <RiUpload2Line />
                Upload
                <RxCaretDown />
            </div>

            <div className="same_degn">
                <IoMdSync />
                Sync
            </div>

            <div className="same_degn">
                <BsFillLightningChargeFill />
                Automate
                <RxCaretDown />
            </div>
        </section>




        <span style={{ 
            fontSize:'19px', 
        fontWeight:'bold', 
        marginLeft:'10px', 
        position:'relative', 
        top:'0.1em', 
        color:'#444' 
    }}
        >Shared Documents 4/4</span>

        <section className="aiii" 
        // onClick={modalToggler}
        >
            <div className="files_wrapper">
                <img 
                    onClick={showModal}
                    alt="purchase-pdf"
                    className="docs"
                    src={workSheet}
                    style={{
                        // width: '190px',
                        height:'190px',
                    }}
                />
            </div>

            <div className="files_wrapper">
                <img 
                    onClick={showModal}
                    alt="purchase-pdf"
                    className="docs"
                    src={payroll}
                    style={{
                        height:'198px',
                    }}
                />
            </div>

            <div className="files_wrapper">
                <img 
                    onClick={showModal}
                    alt="purchase-pdf"
                    className="docs"
                    src={dataSheet}
                />
            </div>

            <div className="files_wrapper">
                <img 
                    onClick={showModal}
                    alt="purchase-pdf"
                    className="docs"
                    src={companyProfile}
                />
            </div>

            {/* <br/> */}

            
        </section>

        

    </article>)
};

export default Docs;